.node-teaser-display {
  border-bottom: 1px solid $border-color;
  padding-bottom: 20px;
  margin-bottom: 20px;
  .post-title {
    a {
      font-size: 16px;
    }
  }
  .field-type-taxonomy-term-reference {
    margin-top: 15px;
    .field-items > .field-item {
      border: 1px solid $brand-primary;
      padding: 4px 8px;
      float: left;
      margin-right: 10px;
      &:active,
      &:focus,
      &:hover {
        background: $brand-primary;
        a {
          color: $white;
        }
      }
    }
  }
}
.post-block {
  background: $white;
  .post-image {
    overflow: hidden;
    img {
      width: 100%;
      @include transition(all 0.4s);
    }
    &:hover img {
      -ms-transform: scale(1.2) rotate(6deg);
      -webkit-transform: scale(1.2) rotate(6deg);
      transform: scale(1.2) rotate(6deg);
    }
  }
  .post-title {
    padding: 5px 0;
    h2,
    h3,
    h4 {
      margin: 0;
    }
    a {
      font-size: 14px;
      line-height: 20px;
      font-weight: 600;
    }
  }
  .post-meta {
    margin-bottom: 6px;
    font-size: 11px;
    color: #A4A4A4;
    float: left;
    width: 100%;
    > div {
      float: left;
      display: inline-block;
      margin-right: 20px;
      @media(max-width: $screen-sm-max) {
        margin-right: 10px;
      }
      &:last-child {
        margin-right: 0;
      }
    }
    a {
      color: #A4A4A4;
      &:hover {
        text-decoration: underline;
      }
    }
    .post-created {
      text-transform: uppercase;
      position: relative;
      padding-left: 20px;
      &:before {
        content: "\f133";
        font-family: $font-icon;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
      }
    }
    .post-categories {
      position: relative;
      padding-left: 20px;
      line-height: 1;
      &:before {
        content: "\f02c";
        font-family: $font-icon;
        color: $purple;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
      }
      a {
        // 	text-transform: uppercase;
        color: $purple;
        @include transition(all 0.35s);
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  .content-after {
    padding: 10px 0 0;
  }
  &.post-style-list {
    ul {
      padding: 0;
    }
    .post-image {
      @include size(70px, auto);
      float: left;
    }
    .post-meta-wrap {
      float: left;
      width: calc(100% - 75px);
      padding-left: 15px;
      .post-title a {
        font-weight: 400;
        font-size: 14px;
        padding: 5px 0;
      }
    }
    .content-after {}
  }
  .more {
    float: right;
    margin-top: 4px;
    border-radius :0;
  }
}
.gallery-teaser-display {
  .post-block {
    position: relative;
    .post-content {
      @include transition(all 0.35s);
      @include opacity(0);
      text-align: center;
      position: absolute;
      top: 0;
      left: 0;
      padding: 30px 15px 15px;
      @include size(100%, 100%);
      z-index: 9;
      background: rgba(0, 0, 0, 0.5);
      *,
      a {
        color: $white!important;
      }
      a:hover {
        color: $brand-primary!important;
      }
    }
    &:hover {
      .post-content {
        @include opacity(1);
      }
    }
  }
}
//Post skin dark
.style-dark {
  .post-block {
    background: $nocolor;
    .post-title {
      background: $nocolor;
      a {
        color: $white;
        &:hover {
          color: $brand-primary;
        }
      }
    }
  }
}
.post-slideshow {
  div[class*="col-"] {
    padding: 0;
  }
}
//----------------- Display post slider ------------------------
.post-slider {
  &.post-block {
    position: relative;
    .post-meta-wrap {
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0px, rgba(0, 0, 0, 0.99) 100%) repeat scroll 0 0 rgba(0, 0, 0, 0);
      background: -webkit-linear-gradient(to bottom, rgba(0, 0, 0, 0) 0px, rgba(0, 0, 0, 0.99) 100%) repeat scroll 0 0 rgba(0, 0, 0, 0);
      background: -o-linear-gradient(to bottom, rgba(0, 0, 0, 0) 0px, rgba(0, 0, 0, 0.99) 100%) repeat scroll 0 0 rgba(0, 0, 0, 0);
      background: -moz-linear-gradient(to bottom, rgba(0, 0, 0, 0) 0px, rgba(0, 0, 0, 0.99) 100%) repeat scroll 0 0 rgba(0, 0, 0, 0);
      width: 100%;
      padding: 20px;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 9;
      @include transition(all 0.35s);
      .post-title a {
        padding: 5px 0;
        font-size: 26px;
        color: $white;
        font-weight: 400;
        &:hover {
          color: $brand-primary;
        }
      }
    }
    .post-meta {
      padding: 5px 0;
    }
    .views-field {
      color: $white;
    }
    .views-field-title {
      a {
        font-weight: 700;
        color: $white;
        line-height: 1.2;
      }
    }
    .views-field-tags {
      &:before {
        color: $white;
      }
      a {
        color: $white;
        font-weight: 400;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    &:hover {
      .post-body {
        @include opacity(1);
        height: 60px;
      }
    }
  }
  &.v1 {
    &.post-block {
      .post-meta {
        .post-categories {
          &:before {
            content: "\f07b";
            color: $red-dark;
          }
        }
      }
    }
  }
  &.v2 {
    .post-inner {
      position: relative;
      .image {
        &:after {
          @include size(100%, 100%);
          z-index: 9;
          background: rgba(0, 0, 0, 0.6);
          position: absolute;
          top: 0;
          left: 0;
          content: "";
        }
      }
      .views-field-title {
        margin: 15px 0;
        float: left;
        width: 100%;
        @media(max-width: $screen-xs-max) {
          margin: 0;
        }
        a {
          font-size: 30px;
          text-transform: normal;
          @media(max-width: $screen-xs-max) {
            font-size: 14px;
          }
          &:hover {
            color: $brand-primary;
          }
        }
      }
      .post-meta-wrap {
        padding: 0 10%;
      }
      .views-field-body {
        font-size: 16px;
        @media(max-width: $screen-sm-max) {
          display: none;
        }
      }
      .readmore {
        margin-top: 30px;
        @media(max-width: $screen-sm-max) {
          display: none;
        }
        a {
          color: $white;
          padding: 10px 15px;
          text-transform: uppercase;
          border: 1px solid $white;
          text-transform: uppercase;
          display: inline-block;
          &:hover {
            background: $brand-primary;
            border-color: $brand-primary;
          }
        }
      }
      .post-meta-wrap {
        background: none!important;
        text-align: center;
        top: 50%;
        margin-top: -120px;
        @media(max-width: $screen-sm-max) {
          margin-top: -40px;
          top: 40%;
        }
        .post-categories {
          width: 100%;
          padding: 0;
          color: $brand-primary;
          &:before {
            content: none;
          }
          a {
            font-size: 14px;
            color: $brand-primary;
          }
        }
      }
    }
  }
}
//--------------------- Display post stick --------------------------
.post-style-stick {
  .view-content {
    .view-list > ul {
      > li {
        border-bottom: 1px dashed #e1e1e1;
        display: block;
        float: right;
        overflow: hidden;
        width: 50%;
        clear: right;
        @media(max-width: $screen-sm-max) {
          width: 100%;
          clear: both;
        }
        .post-meta,
        .post-meta-wrap,
        .post-title {
          clear: both;
        }
        &:after {
          visibility: hidden;
          display: block;
          content: ".";
          clear: right;
          height: 0;
        }
        &:not(:first-child) {
          margin-bottom: 15px;
          padding-bottom: 15px;
          .post-image {
            display: none;
          }
          .post-meta,
          .post-meta-wrap,
          .post-title {
            clear: none!important;
          }
          .content-body,
          .post-body,
          .post-content-inner {
            display: none;
          }
          .post-title {
            padding: 0;
            a {
              font-size: 14px;
              line-height: 19.5px;
              display: block;
            }
          }
          .post-meta {
            padding: 0;
          }
        }
        &:first-child {
          border-bottom: 0 none;
          display: block;
          float: left;
          padding-right: 15px !important;
          width: 100%;
          // width: 50%;
          clear: none;
          padding-bottom: 0;
          @media(max-width: $screen-sm-max) {
            width: 100%;
            clear: both;
          }
          .post-title {
            a {
              font-size: 21px;
              line-height: 1;
            }
          }
          .post-meta {
            margin: 15px 0 0;
          }
          &:after {
            content: none;
          }
        }
      }
    }
  }
  &.image {
    .view-content {
      .view-list > ul {
        > li {
          &:not(:first-child) {
            margin-bottom: 15px;
            padding-bottom: 15px;
            .post-image {
              display: inline-block;
              @include size(85px, auto);
              float: left;
            }
            .post-content {
              padding-left: 95px;
            }
            .post-meta,
            .post-meta-wrap,
            .post-title {
              clear: none!important;
            }
            .content-body,
            .post-body,
            .post-content-inner {
              display: none;
            }
          }
        }
      }
    }
  }
  &.list {
    .view-content {
      .view-list > ul {
        > li {
          width: 100%!important;
          &:first-child {
            border-bottom: 1px dashed #e1e1e1;
            display: block;
            float: left;
            padding-right: 0 !important;
            clear: none;
            margin-bottom: 15px;
            padding-bottom: 15px;
          }
        }
      }
    }
  }
}
//------------------------- Grid post -------------------------------
.post-style-grid {
  .post-meta {
    margin-top: 10px;
  }
}
//------------------------- Display post Highlight ------------------
.list-highlight-post {
  .view-list ul li {
    padding: 0 !important;
    margin: 0;
    .post-block {
      background: $brand-primary;
      .post-image {
        padding: 0;
      }
      .post-meta-wrap {
        padding: 20px;
        overflow: hidden;
        * {
          color: $white;
          a:hover {
            color: $brand-primary;
          }
        }
      }
      .post-body {
        padding-top: 10px;
      }
    }
    &:nth-child(2n) {
      .post-image {
        float: right!important;
      }
    }
  }
}
//------------------ Display post list ---------------------
.post-style-list {
  .post-block {
    clear: both;
    .post-image {
      width: 35%;
      float: left;
      img {
        height: auto;
        width: 100%;
      }
    }
    .post-content {
      width: 65%;
      padding-left: 15px;
      float: left;
    }
    .post-title {
      padding-top: 0;
    }
  }
  &.small {
    .post-image {
      width: 30%;
    }
    .post-content {
      width: 70%;
      padding-left: 10px;
    }
    .post-title {
      padding-top: 3px;
      a {
        font-size: 14px!important;
      }
    }
    .post-meta {
      padding: 0;
    }
  }
}
//---------------- Display post video ----------------------------
.view-videos-block {
  .field-content {
    padding: 0 15px;
  }
  &.large {
    .field-content {
      padding: 0;
    }
  }
  .post-block {
    background: $nocolor;
    position: relative;
    .post-image a {
      &:after {
        @include transition(all 0.35s);
        content: "";
        position: absolute;
        background: rgba(0, 0, 0, 0.3);
        @include size(100%, 100%);
        top: 0;
        left: 0;
        z-index: 9;
      }
      &:before {
        @include transition(all 0.35s);
        content: "\f01d";
        color: $brand-primary;
        font-family: $font-icon;
        position: absolute;
        @include size(42px, 42px);
        top: 35%;
        left: 50%;
        margin-left: -21px;
        margin-top: -21px;
        font-size: 45px;
        line-height: 1;
        color: $white;
        z-index: 99;
      }
    }
    .post-title {
      padding: 5px 0;
      position: absolute;
      bottom: 0;
      @include size(100%, auto);
      z-index: 99;
      padding: 20px;
      a {
        font-size: 14px;
        color: $white;
        display: inline-block;
        line-height: 1.3;
        &:hover {
          color: $brand-primary;
        }
      }
    }
    &:hover {
      .post-image a {
        &:before {
          display: none;
        }
      }
    }
  }
}
//------------- Post Carousel -------------
.view-post-carousel {
  .row {
    margin-left: -3px;
    margin-right: -3px;
  }
  .post-absolute {
    position: relative;
    padding: 0 3px;
    .views-field-field-post-image {
      position: relative;
      &:after {
        @include transition(all 0.35s);
        content: "";
        position: absolute;
        background: rgba(0, 0, 0, 0.2);
        @include size(100%, 100%);
        top: 0;
        left: 0;
        z-index: 9;
      }
    }
    .content-post {
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0px, rgba(0, 0, 0, 0.99) 100%) repeat scroll 0 0 rgba(0, 0, 0, 0);
      background: -webkit-linear-gradient(to bottom, rgba(0, 0, 0, 0) 0px, rgba(0, 0, 0, 0.99) 100%) repeat scroll 0 0 rgba(0, 0, 0, 0);
      background: -o-linear-gradient(to bottom, rgba(0, 0, 0, 0) 0px, rgba(0, 0, 0, 0.99) 100%) repeat scroll 0 0 rgba(0, 0, 0, 0);
      background: -moz-linear-gradient(to bottom, rgba(0, 0, 0, 0) 0px, rgba(0, 0, 0, 0.99) 100%) repeat scroll 0 0 rgba(0, 0, 0, 0);
      width: 100%;
      padding: 20px;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 9;
      .post-title {
        padding: 5px 0;
        h2,
        h3,
        h4 {
          margin: 0;
        }
        a {
          font-size: 14px;
          line-height: 20px;
          font-weight: 600;
          color: $white;
          &:hover {
            color: $brand-primary;
          }
        }
      }
      .post-categories {
        position: relative;
        padding-left: 20px;
        &:before {
          content: "\f02c";
          font-family: $font-icon;
          color: $brand-primary;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;
        }
        a {
          text-transform: uppercase;
          color: $brand-primary;
          @include transition(all 0.35s);
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
