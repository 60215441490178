.sidebar {
  padding-left: 50px;
}

.block-simpleads {
  .simplead-container {
    max-width: 300px;
    @media only screen and (max-width: 767px) {
      margin-right: auto;
      margin-left: auto;
    }
  }
  @media only screen and (min-width: 768px) {
    &.affix {
      top: 90px;
      max-width: 360px;
    }
  }
}

@media only screen and (min-width: 768px) {
  // Encart interviews
  #block-block-6 {
    img {
      display: block;
      margin: auto;
    }
  }
}

#block-views-edito-e-quotidien-edito, #block-views-edito-e-quotidien-communique {
  width: 300px;
  margin: auto;
  .block-title {
    padding: 0 0 5px 0;
    span {
      width: 100%;
      &::before {
        right: 0;
      }
    }
  }
  .views-field-view-node a {
    color: $brand-primary;
    display: block;
    float: right;
    margin-bottom: 30px;
  }
  strong a {
    color: $gray-base;
  }
}
