@media only screen and (min-width: 1200px) {
  .navbar-header {
    .logo {
      h1,
      img,
      span {}
      h1,
      span {
        letter-spacing: 2px;
        &::before {}
      }
      h1 {}
    }
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1439px) {}
