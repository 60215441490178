#drupal_tabs {
  margin: 20px 0;
}

#views-live-preview {
  max-width: 1100px;
  overflow-x: auto;
}
.colorpicker {
  z-index: 9999999!important;
}
.gavias-skins-panel {
  @include box-shadow(0 0 5px rgba(0, 0, 0, 0.5));
  background:#fff;
  right: -403px;
  position: fixed;
  height: auto;
  top: 0px;
  bottom: 0;
  @include transition(all 0.35s);
  width: 400px;
  z-index: 999;
  .gavias-skins-panel-inner {
    bottom: 0;
    padding: 30px 10px 10px;
    height: auto;
    overflow-x: hidden;
    overflow-y: scroll;
    position: absolute;
    top: 0;
    width: 100%;
  }
  &.active {
    right: 0!important;
  }
  .control-panel {
    @include box-shadow(0 0 5px rgba(0, 0, 0, 0.5));
    position: absolute;
    top: 150px;
    left: -40px;
    @include size(40px, 40px);
    background: $theme-color;
    color: $white;
    font-size: 18px;
    line-height: 40px;
    text-align: center;
    &:hover {
      cursor: pointer;
    }
  }
  .panel-skins-content {
    padding-bottom: 20px;
    overflow: hidden;
    &:last-child {
      padding-bottom: 0;
    }
    .title {
      font-size: 14px;
      font-weight: 700;
      padding-bottom: 10px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      text-transform: uppercase;
      color: $black;
      margin-bottom: 10px;
    }
    .item-color {
      @include size(52px, 52px);
      @include border-radius(4px);
      border: 1px solid #ccc;
      display: inline-block;
      margin: 5px 6px 0 6px;
      border: 3px solid $nocolor;
      &:hover {
        cursor: pointer;
      }
      &.default {
        background: #09C;
      }
      &.green {
        background: #4C9D65;
      }
      &.lilac {
        background: #8F6684;
      }
      &.orange {
        background: #EB866A;
      }
      &.red {
        background: #cc562b;
      }
      &.yellow {
        background: #ecc311;
      }
      &.active {
        border-color: $black;
      }
    }

    .layout {
      width: 80px;
      margin-right: 10px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      @include border-radius(4px);
      padding: 10px 0;
      text-align: center;
      text-transform: uppercase;
      display: inline-block;
      &:hover {
        cursor: pointer;
      }
      &.active {
        background-color: $theme-color;
        color: $white;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
  &.gavias-skin-demo {
    top: 200px;
    bottom: auto!important;
    padding: 20px!important;
    width: 260px!important;
    right: -263px!important;
    .control-panel {
      top: 75px!important;
    }
    &.active {
      right: 0!important;
    }
  }
}

#customize-gavias-preivew {
  .form-group {
    position: relative;
    float: left;
    width: 100%;
    label {
      width: 150px;
      float: left;
      padding-top: 10px;
      font-size: 12px;
      font-weight: 400;
    }
    input, select {
      width: calc(100% - 150px);
      float: left;
    }
    .colorselector {
      width: calc(100% - 150px);
      input {
        width: 100%!important;
      }
      .input-group-addon {
        border: 1px solid #222;
        i {
          @include size(30px, 20px); 
        }
      }
    }
  }  
}  
.gavias_customize_form {
  .form-group.action {
    float: left;
    width: 100%;
    margin-top: 20px;
    input {
      width: 45%;
      margin-left: 10px;
      color: $black;
      margin-bottom: 10px;
      padding: 5px 10px!important;
    }
  }
  #gavias_profile_customize_name {
    float: left;
    display: inline-block;
  }
  #gavias_profile_customize_new {
    width: 150px;
    float: left;
    display: inline-block;
    height: 33px;
  }
}