// style module FAQ

.faq-category-indent {
  padding-left: 20px;
}

.faq-more-link {
  font-size: 0.85em;
  margin-left: 30em;
  margin-top: 0;
  margin-bottom: -1.25em;
  text-align: right;
}

.faq-top-link {
  font-size: 0.8em;
  margin-top:0;
  margin-bottom: 0;
  padding-bottom: 1.0em;
}

.faq-question {
  padding-top: 5px;
  padding-left: 20px;
}

.faq-answer {
  padding-left: 40px;
}

.faq-answer .faq-answer-label {
  float: left;
  margin-right: 0.5em;
}

.faq-dl-hide-answer {
  padding-bottom: 15px;
}

img.faq-tax-image {
  float: left;
  padding: 0px 3px 0px 3px;
}

div.faq-detailed-question {
  font-style: italic;
}

.faq .collapsible {
	display: block;
}

.faq .collapsed {
	display: none;
}

.faq #faq-expand-all {
  text-align: right;
  display: none;
}
.faq #faq-expand-all a.faq-expand-all-link,
.faq #faq-expand-all a.faq-collapse-all-link {
  display: none;
}


.page-covid19-faq .main-container .view-faq .view-content ul li .views-field .faq-question-answer .faq-answer .field-item ul {
  overflow: inherit;
  list-style-type: initial;
  margin-left: 15px;
  li {
    border: none;
    margin-top: 10px;
  }
} 