.region-navigation {
  position: relative;
}

.navbar-header {
  position: relative;
  margin-right: auto !important;
  margin-left: auto !important;
  display: block;
  float: none;
  text-align: center;
  @media only screen and (max-width: 991px) {
    margin-top: 65px;
  }
  // @media only screen and (min-width: 768px) {
  //   height: 150px;
	//  // &::before {
	//    // background: url(../img/logo-corner-jfr2017.png) no-repeat;
	//    // width: 256px;
	//    // height:130px;
	//    // width: 427px;
	//    // height: 178px;
	//    // position:absolute;
	//    // background-size: cover;
	//    // margin-left: -71px;
	//  // }
  //   // &::before {
  //   //   background: url(../img/logo-corner-jfr2018.png) no-repeat;
  //   //   width: 283px;
  //   //   height:89px;
  //   //   top:15px;
  //   //   position:absolute;
  //   //   background-size: cover;
  //   // }
  // }

  .encart-header {
    display:none;
    @media only screen and (min-width: 992px) {
      display:block;
      background: url(../img/parallelogramme.png) no-repeat;
      background-size: cover;
		  width: 259px;
		  height:168px;
		  position: relative;
		  float: right;
      z-index:1;
		  padding: 40px 25px 0 25px;
		  font-weight: bold;
      color: $white;
      // margin-left: 271px;
      right: 22%;
      margin-bottom: 35px;
      margin-top: -25px;
    }
    @media only screen and (min-width: 1440px) {
      right: 11%;
    }
    @media only screen and (min-width: 1600px) {
      right: 22%;
    }
    @media only screen and (min-width: 1920px) {
      right: 21%;
    }
  }
  .logo {
    color: $white;
    text-decoration: none;
    position: relative;
    top: 15px;
    @media only screen and (min-width: 768px) {
      // top: 40px;
      // left: 160px;
      top: 50px;
      text-align: left;
      img {
        max-width: 360px;
      }
    }
    @media only screen and (min-width: 992px) {
      display: block;
      margin: auto;
      position: absolute;
      left: 0;
      right: 0;
    }
    h1,
    span {
      position: relative;
      top: 14px;
    }
    h1 {
      margin: 0;
      padding-bottom: 0;
      border-bottom: none;
      font-weight: 400;
    }
  }
}
.navbar {
  .affix {
    z-index: 3;
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    background: $brand-primary;
  }
}

#block-system-main-menu {
  line-height: 2.3;
  .menu {
    li {
      &::after {
        display: none;
      }
    }
    > li {
      position: relative;
      padding: 3px 8px;
      display: inline-block;
      > a, a {
        &:hover, &:focus {
          background: transparent;
        }
      }
      > a {
        color : $white;
        padding-left: 0;
        font-weight: 700;
        text-transform: uppercase;
        padding: 5px 8px;
        &:after {
          display: none;
        }
      }
      a {
        text-transform: uppercase;
        font-weight: 700;
        font-size: 13px;
        padding-left :0;
      }
      .dropdown-menu {
        padding: 0 10px;
        background: $white;
        border: none;
        border-bottom: 2px solid $theme-color;
        border-radius: 0;
        margin-top: -5px;
        li {
          border-bottom: 1px dashed rgba(0,0,0,0.1);
          padding: 3px 0;
          &:after {
            display: none;
          }
          a {
            text-transform: uppercase;
            font-weight: normal;
            color: $gray-base;
            font-size: 13px;
            padding: 5px 8px;
            border: none;
          }
        }
        .active {
          > a, > a:hover, > a:focus {
            background: transparent;
          }
        }
      }
    }
    .caret {
      display: none;
    }
  }
  @media only screen and (min-width: $screen-lg-min) {
    // Affichage du menu au survol
    .dropdown:hover {
      > .dropdown-menu {
        display: block;
        margin-top: 0;
      }
    }
  }
}

/* responsive menu */
.tinynav {
  display: none;
  font-size: 16px;
}

@media screen and (max-width: 991px) {
  .tinynav {
    background: #fff;
    border: medium none;
    color: $brand-primary;
    display: block;
    font-family: Verdana,Geneva,sans-serif;
    font-size: 17px;
    line-height: 38px;
    margin-bottom: 0;
    margin-left: auto;
    overflow: hidden;
    width: 100%;
  }
  #search-block-form {
    display: none;
  }
} 

@media screen and (max-width: 991px) {
  #block-system-main-menu .block-inner .block-content div > ul.nav {
    display: none;
  }
}