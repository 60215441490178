.view-list {
  ul {
    overflow: hidden;
    padding-left: 0;
    li.view-list-item {
      width: 100%;
      padding-bottom: 30px;
      overflow: hidden;
      margin: 0;
      .post-block {
        margin: 0;
      }
      &:last-child {
        padding-bottom: 0;
      }
    }
  }
}

.views-view-grid {
  .views-row {
    margin-bottom: 30px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

//Slider for home
.view-slider {
  &.v1 {
    .carousel-item {
      float: left;
      padding-right: 3px!important;
    }

    .post-large {
      img {
        width: 100%;
        height: auto;
      }
      .post-meta-wrap {
        padding: 30px;
      }
      .views-field-title a {
        display: block;
        font-size: 22px;
        padding-bottom: 10px;
        @media(max-width: $screen-sm-max) {
          font-size: 16px;
        }
      }
      .views-field-body {
        @media(max-width: $screen-sm-max) {
          display: none;
        }
      }
    }
    div {
      > .post-small {
        width: 50%!important;
        background: transparent!important;
        float: left;
        .post-small-item {
          background: transparent;
          width: 100%!important;
          height: auto!important;
          float: left;
          clear: both;
          padding-bottom: 3px;
          margin: 0;
          img {
            width: 100%;
            height: auto;
          }
        }
        .views-field-title a {
          @media(max-width: $screen-sm-max) {
            font-size: 12px;
          }
        }
        .views-field-body {
          display: none;
        }
      }
    }
    .item {
      position:relative;
      overflow: hidden;
      .xcolor {
        width: 100%;
        text-align: center;
        i {
          background-color: #fff;
          color: $theme-color;
          width: 30px;
          height: 30px;
          @include border-radius(50%);
          padding: 7px;
          &:hover {
            background-color: #000;
            //color: $theme-color-rgba;
          }
        }
      }
      .description {
        position: absolute;
        @include opacity(0);
        -webkit-animation-duration: 0.6s;
        animation-duration: 0.6s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        height: 100%;
        width: 100%;
        top: 0;
        z-index: 1;
        text-align: center;
        padding-top: 15%;
        .date-post {
          color: #fff;
          font-weight: 700;
          font-size: $font-size-base;
          text-align: center;
          span {
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);
          }
        }
        .body a {
          display: block;
          text-align: center;
          color: #fff;
          font-weight: 400;
          font-size: $font-size-base;
          padding: 10px 0;
        }
      }
      &:hover {
        &:before {
          //background: $theme-color-rgba;
          content: "";
          position: absolute;
          width: 100%;
          height: 100%;
          top:0;
          left: 0;
        }
        .description {
          @include opacity(1);
          -webkit-animation-name: fadeInUp;
          animation-name: fadeInUp;
        }
      }
    }
    .post-small {
      .item .views-field-nothing {
        padding: 5% 10%;
      }
    }

    &.page {
      .row {
        .item {
          margin-top: 15px;
          margin-bottom: 15px;
          .views-field-field-gallery-image {
            text-align: center;
          }
          img {
            max-width: 100%;
            height: auto;
          }
          .views-field-nothing {
            padding-top: 5%;
          }
        }
      }
    }
  }
}

.view-covid19{
  #bloc_covid19_visuel{
    float:left;
    width:28%;;
    img {
      width:100%;
    }
  }
  
  #bloc_covid19_liens{
    .more-link{
      text-align: right;
      a{
        color: white;
        background: #e22a10;
        display: inline-block;
        padding: 4px 11px;
      }
    }
     
    .views-row{
      margin-bottom:20px;
      .views-field-field-tags:before, .views-field-field-theme-covid-19:before {
          content: "\f02c";
          font-family: "FontAwesome";
          color: #a22182;
          float: left;
          top: 0;
          left: 0;
          z-index: 1;
          margin-right: 5px;
      }
      .views-field-field-tags, .views-field-field-theme-covid-19{
        a {
          font-size: 11px;
          color: #a22182;
          font-weight: 400;
        }
      }
      
      .views-field-title{
        clear:both;
        font-weight:bold;
      }
    }
  }
}


.page-covid19-reponses{
  .page-header {
    color: #e20512;
  }
  .views-exposed-widget{
    div, label , select {
      display: inline;
      width:auto
    }
  }

  .views-exposed-widgets{
    margin-bottom:30px;
  }

  .views-row{
    border-top: 1px solid #efefef;
    padding: 20px;
  }

  .views-exposed-widget.views-submit-button{
    float:unset;
    display: inline;
    width:auto;
  }

  .views-exposed-widget.views-submit-button button{
    margin: 9px 0 0 0px;
    background-color:#e22a10;
    border:0;
  }


  .views-field-field-tags{
    .field-content:before {
      content: "\f02c";
      font-family: "FontAwesome";
      color: #a22182;
      margin-right:5px;
    }

    .field-content{
      a {
        color: #a22182;
      }
    }
  }

  .views-field-title {
    color: #a22182;
    font-size: 1.3em;
    float: left;
    margin-top: 6px;
  }

  .views-field-view-node a {    
    background-color: #e22a10;
    display: block;
    float: right;
    color: white;
    padding: 5px;
  }
}

// vue FAQ

.page-covid19-faq{
  .main-container{
    padding-bottom: 50px;
    .view-faq {
      .views-widget{
        display: inline-block;
        margin-left:10px;
        .form-type-select{
          width:auto;
          .form-control.chosen-container-multi{
            height:auto;
            border:1px solid;
            .chosen-choices{
              border:unset;
              background:unset;
              box-shadow:unset;
            }
          }
        }
      }
      .views-exposed-form {
        .views-exposed-widget .btn {
          margin-top:0px;
          background-color: #e20512;
          border: 1px solid #e20512;
        }
      }


      .view-content{
        ul{
          padding: 0;
          li:before{
            display:none;
          }
          
          li{
            margin-top: 20px;
            border:1px solid #e9e5e2;
            border-radius: 5px;
            padding-bottom: 0;
            .faq-question-answer{
              .faq-question{
                background:#e9e5e2;
                padding: 8px;
                a{
                  color: #e20512;
                }
                .faq_keywords{
                  float:right;
                  ul{
                    text-align:right;
                    li{
                      display:inline;
                      background:white;;
                      border-radius:3px;
                      padding:3px;
                      font-size:0.9em;
                    }
                  }
                }
              }
              .faq-question.faq-qa-visible{
                a:before{
                  content: " v ";
                }
              }
              .faq-question:not(.faq-qa-visible){
                a:before{
                  content: " > ";
                }
                :target:before {
                  display: inherit;
                  height: 0;
                  margin: 0;
              }
            }
            .faq-answer{
              padding:15px;
            }
          }
        }
      }
    }
  }
}
:target:before {
  display: inherit;
  height: 0;
  margin: 0;
}
.chosen-container-multi .chosen-choices li.search-choice{
  margin: 0px 5px 3px 0;
}
}

.page-covid19 {
  .page-header {
    color: #e20512;
  }
  #bloc_covid19_liens {
    .view-filters {
      #views-exposed-form-covid19-page-1 {
        .views-exposed-form {
          .views-submit-button {
            #edit-submit-covid19 {
              background-color: #e20512;
              border: 1px solid #e20512;
            }
          }
        }
      }
    }
  }
}
