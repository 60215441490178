@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: local('Open Sans Light'), local('OpenSans-Light'), url(https://fonts.gstatic.com/s/opensans/v13/DXI1ORHCpsQm3Vp6mXoaTegdm0LZdjqr5-oayXSOefg.woff2) format('woff2');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans'), local('OpenSans'), url(https://fonts.gstatic.com/s/opensans/v13/cJZKeOuBrn4kERxqtaUH3VtXRa8TVwTICgirnJhmVJw.woff2) format('woff2');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: local('Open Sans Bold'), local('OpenSans-Bold'), url(https://fonts.gstatic.com/s/opensans/v13/k3k702ZOKiLJc3WVjuplzOgdm0LZdjqr5-oayXSOefg.woff2) format('woff2');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  src: local('Open Sans Extrabold'), local('OpenSans-Extrabold'), url(https://fonts.gstatic.com/s/opensans/v13/EInbV5DfGHOiMmvb1Xr-hugdm0LZdjqr5-oayXSOefg.woff2) format('woff2');
}

@font-face {
  font-family: 'brandon';
  font-style: normal;
  font-weight: 900;
  src: url('#{$theme-font-path}brandon-grotesque/BrandonGrotesque-Black.eot') format('eot'),
    url('#{$theme-font-path}brandon-grotesque/BrandonGrotesque-Black.otf') format('opentype'),
    url('#{$theme-font-path}brandon-grotesque/BrandonGrotesque-Black.woff') format('woff'),
    url('#{$theme-font-path}brandon-grotesque/BrandonGrotesque-Black.ttf') format('truetype'),
    url('#{$theme-font-path}brandon-grotesque/BrandonGrotesque-Black.svg#BrandonGrotesque-Black') format('svg');
}

@font-face {
  font-family: 'asenine';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/asenine_.woff') format('woff'),
}
