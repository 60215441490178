footer {
  padding-top: 20px;
  padding-bottom: 20px;
  background: $grey-dark;
  color: $white;
  margin-top: 20px;
  .block {
    margin-bottom: 0;
    .block-title {
      padding: 0;
      span {
        &::before {
          background: $gray-light;
        }
      }
    }
  }
  a {
    color: $white;
  }
  #block-block-3 {
    ul {
      li {
        list-style: none;
        border-bottom: 1px solid $gray-light;
        transition: .2s;
        &:hover {
          background: $gray-dark;
        }
        a {
          line-height: 2.4;
          width: 100%;
          text-decoration: none;
          color: $white;
          padding-left: 5px;
          &::before {
            font-family: "FontAwesome";
            position: relative;
            content: "\f08e";
            left: -5px;
          }
        }
      }
    }
  }
  .share {
    a {}
  }
  .share {
    margin-right: 0;
    li {
      a {
        color: $grey-dark;
        margin-left: 4px;
      }
      &:nth-child(n) {
        background-color: $gray-light;
        &:hover {
          background: $white;
          opacity: 1;
        }
      }
    }
  }
}
