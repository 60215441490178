// Block Layout styles
//----------------------------
.block {
  background: $nocolor;
  margin-bottom: 30px;
  .block {
    margin-bottom: 0 !important;
  }
  .block-title {
    background: none;
    position: relative;
    text-transform: uppercase;
    // border-bottom: 1px solid $border-color;
    padding: 0 0 10px;
    //margin-bottom: 20px;
    h1,
    h2,
    h3,
    h4 {
      margin: 0;
    }
    span {
      display: inline-block;
      font-size: 16px;
      font-weight: 800;
      // text-transform: uppercase;
      position: relative;
      font-family: $font-second;
      &:before {
        content: "";
        @include size(70px, 1px);
        background: $purple;
        bottom: -7px;
        left: 0;
        position: absolute;
      }
    }
  }
  .block-content {
    //padding: 10px;
  }
  &.block-none,
  &.no-padding {
    background: none!important;
    padding: 0 !important;
    .block-content {
      padding: 0 !important;
    }
  }
  &.no-title {
    margin-top: 0;
    border: none;
    &:before {
      content: none;
    }
  }
  &.style-higlight {
    background: #34495E;
    padding-left: 20px;
    padding-right: 20px;
    .block-title {
      border: none;
      span:before {
        content: none;
      }
      h2 {
        margin: 0;
        padding: 0;
        border: none;
        span {
          padding: 10px;
          background: #4E6984;
          color: $white;
        }
      }
    }
    .post-title a {
      color: $white;
    }
    .more-link a {
      color: $white;
      padding: 0 15px 15px;
      display: block;
      &:hover {
        color: $purple;
      }
    }
  }
  &#block-system-main {
    padding-top: 10px;
  }
  &.no-title {
    border: none;
    > .block-title,
    > div > .block-title {
      padding: 0;
      margin: 0;
      border: none;
      &:before {
        content: none;
      }
    }
  }
}
.block-bean {
  .tabs-horizonal {
    @media only screen and (min-width: 480px) {
      margin-top: -50px;
    }
    .nav-tabs {
      margin-bottom: 30px;
      border: 0;
      display: inline-block;
      float: right;
    }
  }
  &.no-title {
    .tabs-horizonal {
      margin-top: 0;
      .nav-tabs {
        float: left;
        display: block;
        border-bottom: 2px solid $border-color;
        padding: 0 0 10px;
        margin-bottom: 20px;
        position: relative;
        width: 100%;
        &:before {
          content: "";
          @include size(80px, 2px);
          background: $purple;
          bottom: -2px;
          left: 0;
          position: absolute;
        }
      }
    }
  }
}
.sidebar {
  .block {
    background: $white;
    margin-bottom: 30px;
    .block-title {
      //padding-left: 17px;
    }
    .block-content {
    }
    .post-block {
      margin-bottom: 20px;
    }
  }
}
.region-main-menu {
  .block {
    background: $nocolor;
    .block-content {
      padding: 0;
    }
  }
}
.footer {
  .block {}
}
//--------- Block recent comment ---------------------
.recent-comment-block {
  .views-field-picture {
    @include rtl-margin-right(10px);
  }
  .views-field-title {
    margin-top: -4px;
    a {
      font-weight: 600;
      // text-transform: uppercase;
    }
  }
  .views-field-name {
    color: #a4a4a4;
    a {
      color: #a4a4a4;
    }
  }
}
//------------ Block newsletter --------------------------
.newsletter-default {
  padding: 20px;
  margin: 0;
  .block-content {
    padding: 0 15%;
    @media(max-width: $screen-sm-max) {
      padding: 0;
    }
    > div {
      > p {
        width: 35%;
        float: left;
        // text-transform: uppercase;
        font-weight: 700;
        font-size: 16px;
        line-height: 36px;
        color: $black;
        @media(max-width: $screen-xs-max) {
          width: 100%;
          float: none;
        }
      }
      > form {
        width: 65%;
        float: left;
        @media(max-width: $screen-xs-max) {
          width: 100%;
          float: none;
        }
      }
    }
    form {
      padding-right: 30px;
      label[for*="edit-mail"] {
        display: none;
      }
      .form-item {
        padding: 0;
        margin: 0;
        width: 70%;
        float: left;
        input[type*="text"] {
          background: $white;
          border: 1px solid $border-color;
          width: 100%;
        }
      }
    }
    #edit-submit {
      width: 30%;
      float: left;
    }
  }
}
ul.widget-twitter {
  > li {
    padding-bottom: 20px;
    margin-bottom: 10px;
    border-bottom: 1px solid $border-color;
    .avata {
      margin-right: 10px;
      text-align: center;
    }
  }
}
.list-tags {
  .view-list {
    ul > li {
      float: left;
      border: 1px solid $border-color;
      display: inline-block;
      width: auto!important;
      padding: 0 !important;
      margin: 0 10px 10px 0 !important;
      a {
        padding: 6px 10px;
        display: block;
        @include transition(all 0.35s);
      }
      &:hover {
        background: $theme-color;
        a {
          color: $white;
        }
      }
    }
  }
}
.contact-info {
  line-height: 28px;
}
