@media only screen and (min-width: 992px) {
  .paragraphs-item-text {
    text-align: justify;
  }
  .navbar-header {
    .logo {
      h1,
      img,
      span {
        display: inline;
      }
      h1,
      span {
        font-size: 4rem;
        letter-spacing: 2px;
        &::before {
          content: "•";
          margin: 0 30px;
          position: relative;
          top: 8px;
        }
      }
    }
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tb-megamenu {
    .nav-collapse {
      ul {
        &.nav {
          > li {
            > a {
              padding: 8px;
            }
          }
        }
      }
    }
  }
}
