#forum {
  width: 100%;
  position: relative;
  right: -1px;
  .name {
    font-weight: bold;
    font-size: 16px;
  }
  .description {
  }
  table {
    width: 100%;
  }
  tr {
    &.odd {
      td {
      }
      background: #f5f5f5;
    }
    &.even td {
      border-bottom: 1px solid $border-color;
    }
    td.container {
      padding: 1em;
      
      .description {
      }
      .name a {

      }
    }
    &.new-topics td {
      &.forum .name {
        background-image: url(#{$base-theme-image-path}/forum-default.png);
      }
    }
  }
  thead {
    th {
      background: #f5f5f5;
      border-left: 1px solid $border-color;
      text-transform: uppercase;
      font-size: 16px;
      padding: 15px;
    }
  }
  td {
    border: 1px solid $border-color;
    padding: 1em;
    a {
      &:hover {
        text-decoration: none;
      }
    }
    &.topics {
      font-weight: bold;
      text-align: center;
      width: 9%;
    }
    &.posts {
      text-align: center;
      width: 9%;
    }
    &.replies {
      text-align: center;
    }
    &.created {
    }
    &.last-reply {
      
    }
    &.forum {
      padding: 0 1em;
      width: 44%;
      padding-top: 12px;
      .name {
        background-position: left 5px;
        background-repeat: no-repeat;
        line-height: 20px;
        padding-left: 30px;
        font-size: 15px;
        font-weight: normal;
        line-height: 25px;      
        a:hover {
        }
      }
      .description {
        font-size: 14px;
        padding-left: 30px;
      }
    }
    &.title {
      a {
      }
      .submitted {
        font-size: 14px;
      }
    }
    &.icon {
      width: 5%;
    }
  }
  .icon {
    div {
      background-image: url(#{$base-theme-image-path}/forum-icons.png);
      background-repeat: no-repeat;
      height: 24px;
      margin: 0 auto;
      width: 24px;
    }
    .topic-status-new {
      background-position: -24px 0;
    }
    .topic-status-hot {
      background-position: -48px 0;
    }
    .topic-status-hot-new {
      background-position: -72px 0;
    }
    .topic-status-sticky {
      background-position: -96px 0;
    }
    .topic-status-closed {
      background-position: -120px 0;
    }
  }

}
.page-forum {
  #page-title {
    margin-bottom: 10px;
  }
  ul.action-links {
    list-style: none;
    margin: 0 0 20px;
    padding: 0;
    li {
      margin-left: 0;
      a {
        line-height: 0;
        display: inline-block;
        border: none;
        padding: 10px;
        text-transform: uppercase;
        vertical-align: middle;     
      }
    }
  }
}