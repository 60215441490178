$bleu-adminimal: #0074bd;
/* Panneaux */
div#panels-ipe-control-container {
  background-color: #333 !important;
}
div.panels-ipe-placeholder {
  background: #bababa;
  border    : 1px dashed #999;
}
div.panels-ipe-draghandle,
div.panels-ipe-nodraghandle {
  background: #ebebeb;
}
div.panels-ipe-newblock {
  -webkit-box-shadow: none;
  -moz-box-shadow   : none;
  box-shadow        : none;
}
.panels-ipe-linkbar {
  li::before {
    content: ' ' !important;
  }
}
/* Titres */
.panels-ipe-placeholder-content {
  h3 {
    color: #333 !important;
    width: auto !important;
  }
}
#modal-title {
  color: #333 !important;
  width: auto !important;
}
/* Suppression des Icones */
.panels-section-column .content-type-button {
  font-size: inherit;
  overflow : visible;
}
.content-type-button {
  img {
    display: none !important;
  }
  div,
  span {
    line-height: 2.5rem;
  }
}
/* Boutons */
.panels-ipe-save {
  background: red !important;
  color     : white !important;
  &::before {
    font-family : 'FontAwesome';
    margin-right: 5px;
    margin-left : -20px;
    content     : '\f0c7';
  }
  &:hover {    background: white !important;
    color     : #333 !important;

  }
}
.panels-ipe-cancel {
  color     : #333 !important;
  &::before {
    font-family : 'FontAwesome';
    margin-right: 5px;
    margin-left : -20px;
    content     : '\f0e2';
  }
}
.panels-ipe-startedit {
  background: transparent !important;
  &::before {
    font-family : 'FontAwesome';
    margin-right: 5px;
    margin-left : -20px;
    content     : '\f013';
  }
}
.panels-ipe-change-layout {
  background: transparent !important;
  &::before {
    font-family : 'FontAwesome';
    margin-right: 5px;
    margin-left : -20px;
    content     : '\f00a';
  }
}
div.panels-ipe-control .form-submit,
div.panels-ipe-pseudobutton-container {
  background           : #fafafa;
  -moz-border-radius   : 0;
  -webkit-border-radius: 0;
  border-radius        : 0;
  a {
    color: #333 !important;
  }
}
div.panels-ipe-control .form-submit:focus,
div.panels-ipe-control .form-submit:hover,
div.panels-ipe-pseudobutton-container:focus,
div.panels-ipe-pseudobutton-container:hover {
  background-image: none;
}
div.panels-ipe-dragtitle span,
div.panels-ipe-handlebar-wrapper li a,
div.panels-ipe-newblock a,
span.panels-ipe-draghandle-icon {
  -moz-border-radius   : 0 !important;
  -khtml-border-radius : 0 !important !important;
  -webkit-border-radius: 0 !important;
  border-radius        : 0 !important;
  background           : #FAFAFA !important;
  background-image     : none !important;
  -webkit-box-shadow   : none !important;
  -moz-box-shadow      : none !important;
  box-shadow           : none !important;
}
/* Liens */
.content-type-button,
.filter-help,
.help-block {
  a,
  a:visited {
    color: $bleu-adminimal !important;
  }
}
/* Modale */
.panels-add-content-modal {
  background: none !important;
}
/* Affiche la modale Média par dessus celle de baragraphe pour parcourrir la médiathèque */
.ui-dialog.ui-front {
  z-index: 6000;
}
