body {
  overflow-x: hidden;
  &.boxed {
    max-width: $container-large-desktop + 20px;
    @include box-shadow(0 0 5px lighten($gray, 50%));
    margin: 0 auto;
    .container {
      width: auto;
    }
  }
}
.main-wrapper {
  padding-right: 0;
  padding-left: 0;
  .affix-top, .affix {
    background: $brand-primary;
  }
  .affix-top {
    //margin-top: 30px;
  }
}
.box-wrapper {
  background-color: #fff;
  margin: 0 auto;
  max-width: 1240px;
  width: 100%;
}
.stuck {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100%;
}
.navbar-default {
  background: url(../img/header-defaut.jpg) no-repeat;
  //background: url(../img/header-jfr2018.jpg) no-repeat;
  background-size: cover;
  // padding-top: 15px;
  @media screen and (max-width: 991px) {
    min-height: 100px;
  }
  .block {
    margin-bottom: 0;
  }
  .header-main {
    padding: 20px 0;
  }
  &.header-v2 {
    .breaking-news {
      padding: 15px 0;
      margin: 0;
      border-bottom: 1px solid $border-color;
    }
    .header-main {
      background: $white;
      border-bottom: 1px solid $border-color;
    }
    &.v3 {
      .main-menu {
        border-top: 2px solid $theme-color;
      }
    }
  }
}

.breaking-news {
  margin-left: 4px;
  .title {
    float: left;
    width: 120px;
    background-color: $theme-color;
    color: $white;
    padding: 5px;
    text-transform: uppercase;
    font-size: 12px;
    font-family: $font-second;
    text-align: center;
  }
  .paragraphs-item-text {
    font-weight: 700;
  }
  .horaire {
    color: #ccc;
  }
  // .views-field-body {
  //   font-style: italic;
  //   @media only screen and (min-width: 768px) {
  //     padding-left: 10px;
  //     display:inline-block;
  //     font-weight: 600;
  //   }
  // }
  .news-content {
    padding-left: 160px;
    padding-top: 3px;
  }
}

.content-full {
  overflow: hidden;
  .content-main-inner {
    padding-top: 15px;
  }
  #page-main-content {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}
div[class*='-md-6'].main-content {
  .post-slider.v2 .views-field-body {
    display: none!important;
  }
}
.area {
  margin-bottom: 20px;
  &.highlighted {}
  &.before_footer {}
  &.after_content {
    .content-inner {
      background: #F6F7F9;
      border: 1px solid $border-color;
    }
  }
}
.col-md-8 {
  position: relative;
  padding-left: 20px !important;
  padding-right: 20px !important;
  &:after {
    bottom: 0;
    content: "";
    display: block;
    position: absolute;
    top: -20px;
    width: 10px;
    border-left: 1px solid #e7e7e7;
    background: transparent linear-gradient(to right, #f0f0f0 0%, white 100%) repeat scroll 0 0;
    left: 100%;
    margin-left: 20px;
    z-index: 1;
  }

}
.main-menu {
  margin-bottom: 20px;
  background: $white;
  @include box-shadow(0px 2px 3px rgba(0, 0, 0, 0.1));
  .region-main-menu {
    background: $white;
  }
}
.footer {
  background: $white;
  &.footer-v2 {
    background: #282B30;
    color: #999;
    a {
      color: #999;
      &:hover {
        color: $theme-color;
      }
    }
    .footer-center {
      padding: 45px 0 20px;
    }
    .block-title {
      border-color: rgba(255, 255, 255, 0.1);
      span {
        color: $white;
      }
    }
    .copyright {
      background: #191A1E;
    }
  }
}
.copyright {
  .copyright-inner {
    padding: 30px 0;
    border-top: 1px solid $border-color;
    .block {
      margin: 0;
    }
  }
}

.breadcrumb {
  color: $gray-light;
  background: none;
  margin: 0;
  padding: 10px;
  margin-bottom: 20px;
}
