// COLOR
$brand-primary: #E20512;
$red: #8ebb3f;
$red-dark: #9E030D;
$green: #86B91F;
$grey-dark: #282B30;
$theme-color: $brand-primary;
$black: #000;
$white: #fff;
$nocolor: transparent;
$theme-color-second: #444;
$border-color-dark: rgba(255, 255, 255, 0.1);
$border-color: rgba(0, 0, 0, 0.1);

// Couleur JFR2019
// $brand-primary: #009fdb;
$purple: #a22182;
// $red-jfr: #be1442;
//$red-dark: #9E030D;
//$green: #126769;
//$grey-dark: #282B30;
//$theme-color: #126769;

// Couleur JFR2018
//$brand-primary: #8ebb3f;
//$red: #126769;
//$red-dark: #9E030D;
//$green: #126769;
//$grey-dark: #282B30;
//$theme-color: #126769;

// Couleur JFR2017
// $brand-primary: #81b801;
// $red: #E20512;
// $red-dark: $brand-primary;
// $green: #86B91F;
// $grey-dark: #282B30;

//== Icons
$fa-search : '\f002';
$fa-cog : '\f013';
$fa-th : '\f00a';
$fa-chevron-left : '\f053';
$fa-chevron-right : '\f054';
$fa-th : '\f00a';
$fa-times-circle : '\f057';
$fa-check-circle : '\f058';
$fa-exclamation-triangle : '\f071';
$fa-floppy-o : '\f0c7';
$fa-undo :' \f0e2';
$fa-user-circle : '\f2bd';
$fa-calendar-check-o : '\f274';
$fa-envelope : '\f0e0';
$fa-external-link : '\f08e';
$fa-caret-down : '\f0d7';
$fa-caret-up : '\f0d8';
$fa-calendar : '\f073';

// FONTS
$fa-font-path: "../fonts/";
$theme-font-path: "../fonts/";
$font-icon: "FontAwesome";
$font-second: "brandon";

// IMAGE
$base-theme-image-path: '../img';
