.field-label {
  margin: 0;
}
.field-name-field-tags {
  float: left;
  .field-items .field-item {
    display: inline-block;
    float: left;
  }
}
