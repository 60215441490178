body {
  overflow-x: hidden;
}
a {
  &:active,
  &:focus,
  &:hover {
    @include transition(all 0.35s);
    outline: none;
  }
}
// images, video
//---------------------------------
img {
  border: 0;
  vertical-align: top;
  max-width: 100%;
  height: auto;
}
.video-responsive {
  height: 0;
  padding-top: 0;
  padding-bottom: 56.25%;
  position: relative;
  overflow: hidden;
  embed,
  iframe,
  object,
  video {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
  }
}
input[type="email"],
input[type="password"],
input[type="tel"],
input[type="text"],
select,
textarea {
  background-color: #fff;
  @include box-shadow(0 0 2px 2px rgba(0, 0, 0, 0.02) inset);
  border: 1px solid $border-color;
  padding: 5px 10px;
}
.list-menu ul li {
  list-style: none;
  padding: 15px 2px;
  font-size: $font-size-base;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  &:last-child {
    border-bottom: none;
  }
}
ul {
  ul {}
  li {
    i {
      margin-right: 5px;
      font-size: 16px;
      min-width: 20px;
      text-align: center;
    }
  }
}
.item-list {
  ul {

    li {
      margin-left: 0;
    }
  }
}
ul.menu {
  > li {
    position: relative;
    padding: 3px 0;
    a {
      padding-left: 16px;
      @include transition(all 0.35s);
      /*&:after {
        content: "\f105";
        font-family: "FontAwesome";
        position: absolute;
        color: #878787;
        left: 0;
        top: 50%;
        margin-top: -12px;
      }*/
      &:hover {
        padding-left: 25px;
      }
    }
  }
}
table {
  width: 100%;
  margin-bottom: $line-height-computed;
  border: 1px solid $table-border-color;
  > tbody,
  > thead,
  > tfoot {
    > tr {
      > td,
      > th {
        border-color: $table-border-color;
        border-width: 1px 1px 1px 0;
        border-style: solid;
      }
    }
  }
  > thead > tr {
    > td,
    > th {
      border-bottom-width: 2px;
    }
  }
}
.no-padding {
  padding: 0 !important;
}
a.disable {
  pointer-events: none;
  cursor: default;
}
/************************* Pagination ************************/
.pager .paginations {
  border-radius: 5px;
  display: inline-block;
  margin: 0 30px;
  padding: 4px 3px;
  a {
    border: 1px solid $border-color;
    font-weight: 700;
    display: inline-block;
    height: 35px;
    line-height: 34px;
    margin: 0 1px;
    text-align: center;
    width: 35px;
    margin-right: 10px;
    &:last-child {
      margin-right: 0;
    }
    &.active {
      background: $theme-color;
      border-color: $theme-color;
      color: $white;
    }
  }
}

// Boutons

.btn-primary {
  border-radius: 0;
}
