@media only screen and (max-width: 479px) {
  #block-views-slidershow-slider-v1 {
    display: none;
  }
  .navbar-header {
    .logo {
      img {
      }
      h1,
      img,
      span {
      }
      h1,
      span {
        font-size: 2rem;

      }
    }
  }
}
@media only screen and (max-width: 767px) {
  .navbar-header {
    .logo {
      img {
        margin: auto;
      }
      h1,
      img,
      span {
        display: block;
      }
      h1,
      span {
        top: 14px;
        letter-spacing: 1px;
        margin-bottom: 20px;
      }
    }
  }
}
@media only screen and (max-width: 991px) {
  .affix {
    position: static;
    width: auto;
    top: 0;
  }
  .tb-megamenu {
    > .nav-collapse {
      margin-top: 20px;
      @include box-shadow(0px 0px 5px rgba(0, 0, 0, 0.5));
      > ul.nav > li {
        border: none!important;
        > a {
          color: $gray-dark!important;
          &:hover {
            color: $theme-color!important;
          }
        }
      }
    }
    .tb-megamenu-button {
      margin: 5px 0 5px 0;
    }
    .nav-collapse .dropdown-menu a {
      border: none;
      padding: 0;
    }
    .dropdown-menu {
      color: $gray-dark!important;
      a {
        color: $gray-dark !important;
      }
    }
    .tb-megamenu-nav {
      background: $white !important;
    }
    .tb-megamenu-button {
      text-transform: uppercase;
      z-index: 102;
      color: $gray !important;
      font-size: 1.2rem !important;
      max-width: 110px;
      text-shadow: none !important;
      background: $white !important;
      box-shadow: none;
      border: 0 !important;
      border-radius: 0 !important;
      padding: 9px !important;
      &:focus,
      &:hover {
        background-color: transparent;
      }
    }
    .dropdown-menu {
      padding: 0 !important;
      .mega-dropdown-inner {
        display: none !important;
      }
    }
  }
}
