.form-control {
  box-shadow: none;
}
#user-login-form {
  .form-item {
    label {
      width: 100%;
    }
    input {
      width: 100%;
    }
  }
  ul {
    width: 100%;
    padding: 10px 0 30px;
    > li {
      width: 49%;
      float: left;
      text-align: center;
      &:first-child {
        margin-right: 2%;
      }
      a {
        width: 100%;
        display: inline-block;
        float: none;
        font-size: 11px;
        text-transform: uppercase;
        font-weight: 600;
        padding: 10px 5px;
      }
    }
  }
}
#user-login {
  ul {
    margin: 0 0 5px;
    li {
      margin: 0;
    }
  }
  li.openid-link {
    background: none;
  }
}
#search-form {
  .panel {
    border: none;
    box-shadow: none;
  }
  .panel-default > .panel-heading {
    border-bottom: none;
  }
}
.hide-label label {
  background: none;
  clip: rect(1px);
}
.exposed-filters .form-item {
  margin-bottom: 10px;
  margin-top: 0;
}
#article-admin-filter ul {
  padding: .5em 0;
  li {
    list-style: none;
  }
}
#edit-operation-wrapper select {
  margin: .5em;
}
div.resizable-textarea {
  textarea {
    margin-top: 0;
    border: 1px solid $border-color;
  }
}
.search-advanced .criterion {
  float: none;
  margin: 0 5px;
}
#edit-keywords-fieldset .form-item {
  &:first-child {
    margin-top: 5px;
  }
}
#user-admin-filter ul {
  li {
    list-style: none;
  }
}
.field-type-text-long fieldset {
  padding: 10px 0;
  border: $border-color;
  .form-item {
    margin: 0;
    padding: 0 0 0 15px;
  }
}
#comment-form {
  label {
    display: inline-block;
    font-size: 18px;
    font-weight: normal;
    margin-bottom: 5px;
  }
}
#contact-site-form {
  .form-checkbox {
    margin-left: 0;
  }
}
#comment-form-wrapper {
  .form-item {
    label {
      min-width: 120px;
      display: inline-block;
      font-weight: 700;
      text-transform: uppercase;
      font-size: 14px;
    }
  }
}
