// Box Size
// -------------------------
@mixin box-size($background, $padding-top,$padding-bottom) {
  .container {
    background: $background;
    padding-top: $padding-top;
    padding-bottom: $padding-bottom;
  }
}

// Button
// -------------------------
@mixin button-outline($color, $background, $border, $background-hover, $color-hover, $border-hover) {
  color: $color!important;
  background-color: $background;
  border: $border;
  &:hover,
  &:focus,
  &:active,
  &.active {
    color: $color-hover;
    background-color: $background-hover;
    border-color: $background-hover;
  }
  .fa,.icon {
    @include rtl-margin(0,5px,0,0);
    font-size: $icon-font-size-base;
  }
}

// Block
// -------------------------
@mixin block-variant($border, $heading-text-color, $heading-bg-color, $heading-border) {
  border-color: $border;
  background: $heading-bg-color;
  & .widget-title {
    & span:before, & span:after { background:$white; }
    color: $heading-text-color;
    background-color: $heading-bg-color;
    border-color: $heading-border;
    + .gavias-collapse .widget {
      border-top-color: $border;
    }
  }
  & > .gavias-footer {
    + .gavias-collapse .gavias-body {
      border-bottom-color: $border;
    }
  }
}

@mixin block-elements-styles($border, $heading-text-color, $heading-bg-color, $heading-border, $text-color, $text-color-primary) {
  border-color: $border;
  background: $heading-bg-color;
  & .#{$block-heading-selector} {
    color: $heading-text-color;
    background-color: $heading-bg-color;
    border-color: $heading-border;
    + .gavias-collapse .widget {
      border-top-color: $border;
    }
  }
  & > .gavias-footer {
    + .gavias-collapse .gavias-body {
      border-bottom-color: $border;
    }
  }
}
/****/
@mixin container-layout-variant($color, $background, $linkcolor,  $topbar-link-color-hover ) {
  background: $background;
  color: $color;
  a {
    color:$linkcolor;
  }
  a:hover {
    color: $topbar-link-color-hover;
  }
}

//== Inline block
//==========================================
@mixin inline-block ($haslayout : true) {
  display: inline-block;
  vertical-align: middle;
  @if $haslayout == true {
    .lt-ie8 & {
      display: inline;
      zoom: 1;
    }
  }
}

//== vertical block
//==========================================
@mixin vertical-center( $width: 100px, $height: 100px) {
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
  width: $width;
  height: $height;
  position: absolute;
}

//== Translate X - Y - Z
//==========================================
@mixin translateX($x) {
  -webkit-transform: translateX($x);
  -ms-transform: translateX($x); // IE9 only
  -o-transform: translateX($x);
  transform: translateX($x);
}

@mixin translateY($y) {
  -webkit-transform: translateY($y);
  -ms-transform: translateY($y); // IE9 only
  -o-transform: translateY($y);
  transform: translateY($y);
}

@mixin translateZ($z) {
  -webkit-transform: translateZ($z);
  -ms-transform: translateZ($z); // IE9 only
  -o-transform: translateZ($z);
  transform: translateZ($z);
}

//== Transform
//==========================================
@mixin transform($argument) {
  -webkit-transform: ($argument);
  -moz-transform: ($argument);
  -ms-transform: ($argument);
  -o-transform: ($argument);
  transform: ($argument);
}

//== Background Size
//==========================================
@mixin background-size($size1,$size2) {
  -webkit-background-size: ($size1,$size2);
  -moz-background-size: ($size1,$size2);
  -ms-background-size: ($size1,$size2);
  -o-background-size: ($size1,$size2);
  background-size: ($size1,$size2);
}

//== Background origin
//==========================================
@mixin background-origin($value1,$value2) {
  -webkit-background-origin: ($value1,$value2);
  -moz-background-origin: ($value1,$value2);
  -ms-background-origin: ($value1,$value2);
  -o-background-origin: ($value1,$value2);
  background-origin: ($value1,$value2);
}

//== Border radius
//==========================================
@mixin border-radius($radius) {
  border-radius         : $radius;
  -webkit-border-radius : $radius;
  -moz-border-radius    : $radius;
  -ms-border-radius     : $radius;
  -o-border-radius      : $radius;
}

//== Text Shadow
//==========================================
@mixin text-shadow($shadow) {
  text-shadow         : $shadow;
  -webkit-text-shadow : $shadow;
  -moz-text-shadow    : $shadow;
  -ms-text-shadow     : $shadow;
  -o-text-shadow      : $shadow;
}

//== Transform Origin
//==========================================
@mixin transform-origin($originX,$originY) {
  -webkit-transform-origin : $originX $originY;
  -moz-transform-origin    : $originX $originY;
  -ms-transform-origin     : $originX $originY; // IE9 only
  transform-origin         : $originX $originY;
}

//== appearance
//==========================================
@mixin appearance() {
  -webkit-appearance : none;
  -moz-appearance    : none;
  -o-appearance      : none;
  -ms-appearance     : none;
  appearance         : none;
}

//== selection
//==========================================
$prefixes: ("-moz-", "");
@mixin selection($color, $background) {
  @each $prefix in $prefixes {
    ::#{$prefix}selection {
      color: $color;
      background: $background;
    }
  }
}

//== animation fill mode
//==========================================
@mixin animation-fill-mode($fill) {
  -webkit-animation-fill-mode: $fill;
  -moz-animation-fill-mode: $fill;
  -o-animation-fill-mode: $fill;
  animation-fill-mode: $fill;
}

//== filter
//==========================================
@mixin filter($argument) {
  filter         : $argument;
  -webkit-filter : $argument;
  -moz-filter    : $argument;
  -o-filter      : $argument;
  -ms-filter     : $argument;
}

// Clear Lists
// -------------------------
@mixin clear-list() {
  padding    : 0;
  margin     : 0;
  list-style : none;
}

// Formart lists widget
// -------------------------
@mixin lists-style() {
  ul,ol {
    @include clear-list();
    li {
      padding: 0 15px;
      border-bottom: $block-sidebar-widget-border;
      &:first-child {
        padding-top: 0;
        background-position: 0 10px;
      }
      &:last-child {
        border-bottom: 0;
        padding-bottom: 0;
      }
      a {
        font-size: 15px;
        color: #4a4a4a;
        line-height: 25px;
        &:hover {
          //    color: $theme-color;
        }
      }
    }
    ul {
      li:first-child {
        padding-top: 14px;
        background-position: 0 24px;
      }
    }
  }
}

// Functions
// --------------------------------------------------

// Position mixin
//==========================================
// @param [string] $position: position type
// @param [list] $args: list of offsets and values
//==========================================
@mixin position($position, $args) {
  @each $o in top right bottom left {
    $i: index($args, $o);
    @if $i
      and $i + 1 <= length($args)
            and type-of( nth($args, $i + 1) ) == number {
      #{$o}: nth($args, $i + 1);
    }
  }
  position: $position;
}

// Absolute positioning mixin
//==========================================
// @param [list] $args: list of offsets and values
//==========================================
@mixin absolute($args) {
  @include position(absolute, $args);
}

// Arrow mixin
//==========================================
// @param [string] $direction: arrow direction
// @param [list] $position: list of offsets and values
// @param [color] $color (inherit): arrow color
// @param [number] $size (1em): arrow size
//==========================================
@mixin triangle($direction, $position, $color: currentColor, $size: 1em) {
  // Make sure the direction is valid
  @if not index(top right bottom left, $direction) {
    @warn "Direction must be one of top, right, bottom or left.";
  }

  @else {
    @include absolute($position); // Position
    @include square(0); // Size
    content: '';
    z-index: 2;
    border-#{opposite-position($direction)}: $size * 1.5 solid $color;
    $perpendicular-borders: $size solid transparent;

    @if $direction == top or $direction == bottom {
      border-left:   $perpendicular-borders;
      border-right:  $perpendicular-borders;
    }

    @else if $direction == right or $direction == left {
      border-bottom: $perpendicular-borders;
      border-top:    $perpendicular-borders;
    }
  }
}

//== margin - padding has value - not rtl ^.^
//==========================================
@mixin margin($margin, $value) {
  margin-#{$margin}: $value !important;
}

@mixin padding($padding, $value) {
  padding-#{$padding}: $value !important;
}

// Font size - rem
//==========================================

@function parseInt($n) { /* 2 */
  @return $n / ($n * 0 + 1);
}

@mixin font-size($property, $values) {
  $px : (); /* 3 */
  $rem: (); /* 3 */

  @each $value in $values { /* 4 */

    @if $value == 0 or $value == auto { /* 5 */
      $px : append($px , $value);
      $rem: append($rem, $value);
    }

    @else {
      $unit: unit($value);    /* 6 */
      $val: parseInt($value); /* 6 */

      @if $unit == "px" {  /* 7 */
        $px : append($px,  $value);
        $rem: append($rem, ($val / 10 + rem));
      }

      @if $unit == "rem" { /* 7 */
        $px : append($px,  ($val * 10 + px));
        $rem: append($rem, $value);
      }
    }
  }

  @if $px == $rem {     /* 8 */
    #{$property}: $px;  /* 9 */
  } @else {
    #{$property}: $px;  /* 9 */
    #{$property}: $rem; /* 9 */
  }
}

//== Border
//==========================================
@mixin border( $coordinates: 0 0 0 0, $colour: $border-color, $style: solid ) {
  $top: nth($coordinates, 1);
  $right: nth($coordinates, 2);
  $bottom: nth($coordinates, 3);
  $left: nth($coordinates, 4);
  @if not(unitless($top)) {
    border-top: $top $style $colour;
  }
  @if not(unitless($right)) {
    border-right: $right $style $colour;
  }
  @if not(unitless($bottom)) {
    border-bottom: $bottom $style $colour;
  }
  @if not(unitless($left)) {
    border-left: $left $style $colour;
  }
}

// State and hover
//==========================================

@mixin state-hover($time,$height,$color) {
  @include box-shadow(inset 0 0 0 0 $color);
  @include transition(all $time cubic-bezier(0.8,0,0,1));
  &:hover {
    @include transition(all $time cubic-bezier(0.8,0,0,1));
    @include box-shadow(inset 0 (-$height) 0 0 $color);
  }
}

//== Flexible Layout
//==========================================

@mixin flexbox {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
}

%flexbox {
  @include flexbox;
}

@mixin inline-flex {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -moz-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

%inline-flex {
  @include inline-flex;
}