// ckeditor.css
.rteleft {
  text-align: left;
}
.rteright {
  text-align: right;
}
.rtecenter {
  text-align: center;
}
.rtejustify {
  text-align: justify;
}
.rteindent1 {
  margin-left: 40px;
}
.rteindent2 {
  margin-left: 80px;
}
.rteindent3 {
  margin-left: 120px;
}
.rteindent4 {
  margin-left: 160px;
}
.ibimage_left {
  float: left;
}
.ibimage_right {
  float: right;
}
article {
  ul {
    li {
      margin-bottom: 10px;
    }
  }
}
a {
  color: #333;
  text-decoration: none;
}
article a {
  color: $theme-color;
}

.alert .gray {
  background: #ddd;
}
