@media only screen and (min-width: 480px) {}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .navbar-header {
    .logo {
      img {}
      h1,
      img,
      span {}
      h1,
      span {
        font-size: 3rem;
      }
    }
  }
}
