.front .print-link {
  display: none !important;
}

#block-views-encart-equotidien-focus, #block-views-encart-equotidien-focus--2 {
  background: #ebf0fa;
  padding: 15px;
  margin-left: -15px;
  margin-right: -30px;
  .nav-tabs {
    display: none;
  }
  .focus-triangle {
    border-top: 30px solid transparent;
    border-bottom: 30px solid transparent;
    border-left: 30px solid $purple;
    position: absolute;
    top: 115px;
    left: 0;
    @media only screen and (max-width: 767px) {
      display: none;
    }
  }
  .post-block {
    background: transparent;
    padding-left: 35px;
  }
}

@media only screen and (min-width: 480px) {
  .block-bean .tabs-horizonal {
    margin-top: 0;
  }
}

#block-bean-derniers-articles-e-quotidien {
  &::before {
    content:'';
    width: 264px;
    height: 66px;
    background: url(../img/equotidien-derniers-articles-jfr2019.png);
    position: relative;
    display: block;
    bottom: -3rem;
  }
  .nav-tabs {
    float:right;
    width: auto;
    border-bottom: 0;
    @media only screen and (max-width: 767px) {
      margin-top: 30px;
    }
    &::before {
      height: 0;
    }
  }
}
