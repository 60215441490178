.gallery-grid .view-list {
  position: relative;
  height: auto;
}

.gallery-grid .view-list ul {
  list-style: none;
  display: block;
  width: 100%;
  margin: 0;
  padding: 0;
}

.gallery-grid .view-list ul:before,
.gallery-grid .view-list ul:after {
  content: '';
  display: table;
}

.gallery-grid .view-list ul:after {
  clear: both;
}

.gallery-grid .view-list ul {
  zoom: 1; /* For IE 6/7 (trigger hasLayout) */
} 

.gallery-grid .view-list ul li {
  -webkit-perspective: 400px;
  -moz-perspective: 400px;
  -o-perspective: 400px;
  -ms-perspective: 400px;
  perspective: 400px;  
  margin: 0;
  padding: 3px;
  float: left;
  position: relative;
  display: block;
  overflow: hidden;
  background: #FFF;
  a {
    z-index: 999;
    display: block;
    position: absolute;
    left: 3px!important;
    right: 3px!important;
    bottom: 3px!important;
    top: 3px!important;
    width: auto!important;
    height: auto!important;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -o-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    -o-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: #333;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    cursor: pointer!important;
    &:hover {
      cursor: pointer;
      pointer: cursor
    }
  }
}   


/* Grid wrapper sizes */
.gallery-grid .view-list-size-1 {
  width: 55%;
}
.gallery-grid .view-list-size-2 {
  width: 100%;
}
.gallery-grid .view-list-size-3 {
  width: 100%;
  margin-top: 0px;
}

/* Shadow style */
.ri-shadow:after,
.ri-shadow:before {
  content: "";
  position: absolute;
  z-index: -2;
  bottom: 15px;
  left: 10px;
  width: 50%;
  height: 20%;
  max-width: 300px;
  max-height: 100px;
  box-shadow: 0 15px 10px rgba(0, 0, 0, 0.7);
  -webkit-transform: rotate(-3deg);
  -moz-transform: rotate(-3deg);
  -ms-transform: rotate(-3deg);
  -o-transform: rotate(-3deg);
  transform: rotate(-3deg);
}
.ri-shadow:after {
  right: 10px;
  left: auto;
  -webkit-transform: rotate(3deg);
  -moz-transform: rotate(3deg);
  -ms-transform: rotate(3deg);
  -o-transform: rotate(3deg);
  transform: rotate(3deg);
}

.gallery-grid .view-list-loading:after,
.gallery-grid .view-list-loading:before {
  display: none;
}

.ri-loading-image {
  display: none;
}

.gallery-grid .view-list-loading .ri-loading-image {
  position: relative;
  width: 30px;
  height: 30px;
  left: 50%;
  margin: 100px 0 0 -15px;
  display: block;
}