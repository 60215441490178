:target::before {
  content:"";
  display:block;
  height:200px; /* fixed header height*/
  margin:-200px 0 0; /* negative fixed header height */
}

.print-link {
  float: right;
  margin-top: -51px;
  position: relative;
  display: inline-block;
  position: relative;
  vertical-align: top;
  width: 31px;
  height: 31px;
  line-height: 31px;
  text-align: center;
  font-size: 17px;
  background-color: $gray-light;
  .print_html {
    margin-left: 0;
  }
  &:hover {
    opacity: .7;
  }
  a {
    color: $white;
    &:hover {
      text-decoration: none;
    }
  }
  img {
    display: none;
  }
}
front .page-header {
  display: none;
}
.front {
  // Alignement horizontal des colonnes de la page d'accueil
  .block#block-system-main {
    padding-top: 0;
    margin-bottom: 0;
  }
  .region-sidebar-second .block:first-child h2.block-title {
    margin-top: 5px;
    &:before {
      content: "";
      @include size(100%, 1px);
      background: $purple;
      bottom: 5px;
      left: 0;
      position: absolute;
    }
  }
}

#block-block-5, .sidebar .block-simpleads {
  max-width: 320px;
  @media only screen and (min-width: 768px) {
    //margin-left: 30px;
    padding-left: 13px;
    z-index: 1;
  }
}
.share {
  float: right;
  margin-right: 35px;
  li {
    display: inline-block;
    position: relative;
    vertical-align: top;
    width: 31px;
    height: 31px;
    line-height: 31px;
    text-align: center;
    font-size: 17px;
    color: #fff;
    &:hover {
      opacity: .7;
    }
    a {
      color: #fff;
      padding-left: 4px;
    }
    &:nth-child(1) {
      background-color: #3f6fa7;
    }
    &:nth-child(2) {
      background-color: #77c7f7;
    }
    &:nth-child(3) {
      background-color: #0e76a8;
    }
  }
}

.img-footer-jfr-2019 {
  margin-top: -66px;
}

.breaking-news-title {
  color: $purple;
}
