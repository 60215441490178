// Bouton recherche

#block-search-form {
  #search-block-form {
    input {
      background-color: $white;
      border: none;
      height: 37px;
      font-style: italic;
      border: 0;
      border-radius: 0;
    }
  }
  .input-group-btn {
    .btn {
      height: 37px;
      width: 37px;
      border-radius: 0;
      border: none;
      border-color: $black;
      padding: 0;
      background: $black;
      font-size: .1px;
      color: $black;
      &::after {
        font-family: $font-icon;
        color: $white;
        content: "\f002";
        font-size: 18px;
      }
    }
  }
}
.search-button {
  margin-top: -10px;
  position: relative;
  z-index: 900;
  right: 15px;
  margin-top: 5px;
  position: absolute;
  a {
    color: $white;
    font-size: 20px;
    .fa-search {
      background: $black;
      padding: 8px 10px;
    }
  }
}

.search-active {
  position: absolute;
  margin-right: 15px;
  margin-top: 5px;
  padding: 0;
  background: $white;
  border: 1px solid #border-color-light;
  display: none;
  color: #1e1e1e;
  font-size: 24px;
  line-height: 30px;
  z-index: 13;
  width: 100%;
  @media only screen and (max-width: 1339px) {
    left: 0;
    width: 100%;
  }
  input {
    border: none;
    font-size: 24px;
    width: 100%;
    border-radius: 0;
    &.form-submit {
      background-color: transparent;
      color: $black;
      position: relative;
      z-index: 1;
      height: 45px;
      margin: 0;
      padding: 10px;
      &:focus {
        outline: none;
        background: transparent;
      }
    }
  }
  .input-group-btn {
    z-index: 3;
    .btn {
      font-size: 16px;
      background-color: transparent;
      color: $gray-light;
      height: 45px;
      margin: 0;
      padding: 10px;
      border: none;
      z-index: 920;
      &:hover {
        box-shadow: none;
      }
    }
    .glyphicon-search:before {
      font-family: $font-icon;
      content: $fa-search;
    }
  }
  &.show {
    display: block;
  }
  .form-group {
    margin-bottom: 0;
  }
}
.logged-in {
  #block-search-form .form-actions {
    right: 10px;
  }
}
