$gris: #ededed;
$gris-fonce: #8f8f8f; // Accordeon
// Alert
// Featured Box Big, Medium, Small
// Image caption
// Notification
// Overlay text
div {
  &[class*="paragraphs-item-"] {
    margin: 0 0 10px;
  }
}
// Accordeon
.panel-heading {
  background: $gris;
  border-radius: 0;
}
.panel-default {
  border: 0;
}
.panel-title {
  a {
    color: $gris-fonce;
  }
}
// Alert
.paragraphs-item-alerte {
  width: 100%;
  background-color: $red-dark;
  border-color: $red-dark;
  &:hover {
    background-color: $brand-primary;
    border-color: $brand-primary;
  }
  .icon {
    float: left;
    margin-right: 10px;
  }
  p {
    text-align: left;
  }
}
// Featured Box
div {
  &[class*="paragraphs-item-featured-box"] {
    .icon {
      border-radius: 100%;
      display: block;
      margin: 0 auto 20px;
      text-align: center;
      transition: opacity .2s linear;
    }
  }
  &[class*="paragraphs-item-featured-box"].lg {
    text-align: center;
    .text {
      max-width: 250px;
      margin: 0 auto 10px;
    }
  }
  &[class*="paragraphs-item-featured-box"].md {
    p {
      &:nth-child(2) {
        display: block;
        height: 10px;
        text-align: justify;
        overflow: hidden;
      }
    }
    .icon {
      display: inline-block;
      align-content: left;
      margin-right: 15px;
    }
  }
  &[class*="paragraphs-item-featured-box"].sm {
    .text {
      height: 60px;
      text-align: justify;
      word-wrap: break-word;
      overflow: hidden;
    }
    .icon {
      display: inline-block;
      align-content: left;
      margin-right: 15px;
    }
  }
}
// Image caption
figure {
  display: table;
  margin: auto auto 10px;
  &.pull-left {
    margin-right: 20px;
  }
  &.pull-right {
    margin-left: 20px;
  }
  figcaption {
    display: table-caption;
    color: #7f7f7f;
    font-size: 12px;
    font-style: italic;
    line-height: 18px;
    max-width: 100%;
    caption-side: bottom;
    margin-top: 5px;
    text-align: justify;
  }
}

// Notification
.paragraphs-item-notification {
  border-width: 1px;
  border-style: solid;
  padding: 20px;
  .icon {
    float: left;
    margin-right: 20px;
  }
}

// En savoir plus
.paragraphs-item-savoir-plus {
  border: 4px solid $theme-color;
  padding: 30px 20px 10px 20px;
  margin-top: 40px !important;
  margin-bottom: 30px !important;
  h2 {
    margin-top: -48px;
    color: #fff;
    background: #231f20;
    max-width: 205px;
    text-align: center;
    padding: 5px 10px;
    position: absolute;
  }
  .savoir-plus-type {
    color : #231f20;
  }
  .savoir-plus-titre {
    color : $theme-color;
    font-size: 16px;
    font-weight: bold;
    text-decoration: none;
  }
}

.paragraphs-item-cas-du-jour {
  background: $theme-color;
  position: relative;
  color: white;
  width: 100%;
  padding: 30px;
  margin-top: 30px !important;
  margin-bottom: 30px !important;
  .titre {
    width: 120px;
    background-color: black;
    position: absolute;
    top: -18px;
    left: 30px;
    margin: auto;
    padding: 7px;
    padding-left: 7px;
    padding-left: 18px;
    font-weight: bold;
    font-style: italic;
    font-size: 18px;
  }
  .type {
    text-transform: uppercase;
  }
  .lien {
    color:white;
    font-weight: bold;
    &:hover {
      text-decoration: underline;
    }
  }
  .btn {
    color: white;
    border: 0;
    background: #bbb;
    position: absolute;
    top: 100px;
    &.reponse {
      background: $brand-primary;
      &:hover {
        background: $brand-primary;
      }
    }
    &:hover {
      background:#999;
    }
  }
}

//  Overlay text
.hovereffect {
  width: 100%;
  height: 100%;
  float: left;
  overflow: hidden;
  position: relative;
  .overlay {
    text-align: center;
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.7);
    opacity: 0;
    filter: alpha(opacity=0);
    transition: all .2s .4s ease-in-out;
    padding: 20px;
    font-size: 1em;
    .texte {
      display: block;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      color: white;
    }
  }
  img {
    display: block;
    position: relative;
    transition: all .2s ease-in;
    width: 100%;
  }
  &:hover .overlay {
    opacity: 1;
    filter: alpha(opacity=100);
    transition-delay: 0s;
    transform: translate(0px, 0px);
  }
  &:hover h2 {
    transform: translateY(0px);
    transition-delay: .5s;
  }
}
